<template>
  <div class="p-10 login-tabs-container">
    <div class="vx-card__title mb-4">
      <h3 class="mb-3 mt-5">Reset your password</h3>
      <p v-show="!message" class="mt-5 mb-2"
        >Please enter your email address. We will send you an email to reset your password. Got a login already?
        <router-link to="/login">Login here</router-link>
      </p>
    </div>

    <form v-show="!message" class="text-left flex-1 flex flex-col">
      <vs-input
        v-validate="'required|email'"
        data-vv-validate-on="blur"
        name="email"
        type="email"
        label-placeholder="Email"
        placeholder="Email"
        v-model="form.email"
        class="w-full mt-6"
      />
      <span class="text-danger text-xs pb-2">{{ errors.first('email') }}</span>

      <vs-button class="mt-5" @click="sendEmail">Send Email</vs-button>
    </form>

    <vs-alert color="success" :active="message ? true : false" class="mt-1">{{ message }}</vs-alert>
    <p v-if="message" class="text-center mt-6 mb-1">Once you've reset you password you can <router-link to="/login">Login here</router-link></p>
  </div>
</template>

<script>
import auth0 from '@/auth0';

export default {
  components: {},
  data: () => ({
    message: undefined,
    form: {
      email: ''
    }
  }),
  methods: {
    async sendEmail() {
      const me = this;
      return await this.$validator.validateAll().then(valid => {
        if (valid) {
          auth0.changePassword(this.form.email).then(message => {
            me.message = message;
          });
        }
      });
    }
  }
};
</script>
